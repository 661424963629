/* Slide-in animation */
@keyframes slideInFromRight {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(0);
    }
  }
  
  /* Slide-out animation */
  @keyframes slideOutToRight {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(100%);
    }
  }
    
  .open {
    animation: slideInFromRight 0.3s forwards;
  }
  
  .closed {
    animation: slideOutToRight 0.3s forwards;
  }
  