@tailwind base;
@tailwind components;
@tailwind utilities;

.btn {
  @apply text-center text-white bg-black border border-black rounded-sm px-6 py-1.5 text-lg font-medium transition hover:bg-black hover:text-white
}

.btn-outline {
  @apply text-center text-black bg-white border border-black rounded-sm px-6 py-1.5 text-lg font-medium transition hover:bg-black hover:text-white
}